const ScrollTo = (elementId, offset = 0) => {
    const element = document.getElementById(elementId);
    if (element) {
        console.log('Scrolling to element:', elementId);
        console.log('Offset:', offset);
        const offsetTop = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
    }
  };
  
  export default ScrollTo;