import EthWithCoinsColor from '../Images/EthWithCoinsColor.svg'
import UpRightArrow from '../Images/UpRightArrowColor.svg'

function LandingPage() {
    return (
        <div class="landingPageMainContainer" id="landingPageComponentTargetElement">
            <div class="landingPageRowContainer">
                <div class="landingPageRowContainerLeftColumn">
                    <h1 class="landingPageMainHeader"><span class="landingPageMainHeaderSpan">Your Web3 Partner for Exceptional Security</span></h1>
                    <h1 class="landingPageDescriptionText">
                        Embark on a journey with a dedicated solo security researcher committed to fortifying your Web3 defenses. 
                        Ensuring your protocol stand resilient in the ever-evolving decentralized landscape.
                    </h1>
                    <div class="landignPageRowButtons">
                        <a href="https://t.me/dimulski" target="_blank" rel="dimulski telegram" class="landingPageLinkStyle">
                            <button class="landignPageContactButton">
                                <h1 class="landingPageContactButtonText">Book an audit</h1>
                            </button>
                        </a>

                        <a href="https://github.com/AtanasDimulski" target="_blank" rel="dimulski portfolio" class="landingPageLinkStyle">
                            <button class="landingPagePortfolioButton">
                                <div class="landingPagePortfolioTextContainer">
                                    <h1 class="landingPagePortfolioTextHeader">Portfolio</h1>
                                </div>
                                <div class="landingPageArrowContainer">
                                    <img class="ladningPageArrow" src={UpRightArrow} />
                                </div>
                            </button>
                        </a>

                    </div>
                    {/* <h1 class="landingPageSmallesHeader"> Helped prevent > 50 bugs</h1> */}
                </div>

                <div class="landingPageRowContainerRightColumn">
                    <img class="landingPageEthImage" src={EthWithCoinsColor}  />
                </div>
            </div>
        </div>
    )
}
export default LandingPage;