import { useState, useEffect } from 'react';
import UpRightArrow from '../Images/UpRightArrowColor.svg'
import ScrollTo from '../Helpers/ScrollTo.js'

function Navbar() {
    const [isMenuActive, setMenuActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = (idToNavigateTo, pixels) => {
        setMenuActive(!isMenuActive);
        setIsOpen(!isOpen);
        ScrollTo(idToNavigateTo, pixels)
    };

    return (
        <div class="navBarUltraMainContainer">
            <div class="navBarMainContainer">
                <div class="navBarLogoContainer">
                    <h1 class="navBarLogoText">Dimulski</h1>
                </div>
                
                <div class="navBarPagesOutterLinkContainer">
                    <a href="https://medium.com/me/stories/public" target="_blank" rel="dimulski medium articles" class="navBarLinkStyleOuter">
                        <button class="navBarIndividualLinksButtonOutter">
                            <div class="landingPagePortfolioTextContainer">
                                <h1 class="navBarPagesLinkHeaderOutter">Articles</h1>
                            </div>
                            <div class="navBarArrowContainer">
                                <img class="navBarArrow" src={UpRightArrow} />
                            </div>
                        </button>
                    </a>

                    <a href="https://github.com/AtanasDimulski" target="_blank" rel="dimulski portfolio" class="navBarLinkStyleOuter">
                            <button class="navBarIndividualLinksButtonOutter">
                                <div class="landingPagePortfolioTextContainer">
                                    <h1 class="navBarPagesLinkHeaderOutter">Portfolio</h1>
                                </div>
                                <div class="navBarArrowContainer">
                                    <img class="navBarArrow" src={UpRightArrow} />
                                </div>
                            </button>
                    </a>
                </div>

                <div class="navBarPagesLinkContainerInner">
                    <button class="navBarIndividualLinksButton"
                        onClick={() => ScrollTo('landingPageComponentTargetElement', 0)}
                    >
                        <h1 class="navBarPagesLinkHeader">Home</h1>
                    </button>

                    <button class="navBarIndividualLinksButton"
                        onClick={() => ScrollTo('servicesComponentTargetElement', 42)}
                    >
                        <h1 class="navBarPagesLinkHeader">Services</h1>
                    </button>

                    <button class="navBarIndividualLinksButton"
                        onClick={() => ScrollTo('workComponentTargetElement' , 45)}
                    >
                        <h1 class="navBarPagesLinkHeader">Work</h1>
                    </button>

                    <button class="navBarIndividualLinksButton"
                        onClick={() => ScrollTo('whyMeComponentTargetElement' , 45)}
                    >
                        <h1 class="navBarPagesLinkHeader">About</h1>
                    </button>

                    <button class="navBarIndividualLinksButton"
                        onClick={() => ScrollTo('reviewsComponentTargetElement' , 45)}
                    >
                        <h1 class="navBarPagesLinkHeader">Reviews</h1>
                    </button>
                    <hr class="navBarHr"></hr>
                    
                    <div class="navBarButtonContainer"> 
                        <a href="https://t.me/dimulski" target="_blank" rel="dimulski telegram" class="navBarLinkStyleContactButton">             
                            <button class="navBarContactButton">
                                <h1 class = "navBarButtonText">Contact</h1>
                            </button>
                        </a>
                    </div>               
                </div>
            </div>
            {/* Mobile Navbar */}
            <div class="navBarMobileMainContainer">                
                <div class="mobileNavBarLogoContainer">
                    <h1 class="mobileNavBarLogoHeader">Dimulski</h1>
                </div>
                <button class="navBarMobileHamburgerIconContainer" onClick={() => toggleMenu('', 0)}>
                    <div id="nav-icon3" className={`icon ${isOpen ? 'open' : ''}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
            </div>

            <div className={`menu-container ${isMenuActive ? 'active' : ''}`}>
                    <button class="navBarIndividualLinksButton"
                        onClick={() => toggleMenu('landingPageComponentTargetElement', 0)}
                    >
                        <h1 class="navBarPagesLinkHeader">Home</h1>
                    </button>

                    <button class="navBarIndividualLinksButton"
                        onClick={() => toggleMenu('servicesComponentTargetElement', 42)}
                    >
                        <h1 class="navBarPagesLinkHeader">Services</h1>
                    </button>

                    <button class="navBarIndividualLinksButton"
                        onClick={() => toggleMenu('workComponentTargetElement' , 45)}
                    >
                        <h1 class="navBarPagesLinkHeader">Work</h1>
                    </button>

                    <button class="navBarIndividualLinksButton"
                        onClick={() => toggleMenu('whyMeComponentTargetElement' , 45)}
                    >
                        <h1 class="navBarPagesLinkHeader">About</h1>
                    </button>

                    <button class="navBarIndividualLinksButton"
                        onClick={() => toggleMenu('reviewsComponentTargetElement' , 45)}
                    >
                        <h1 class="navBarPagesLinkHeader">Reviews</h1>
                    </button>
            </div>

        </div>
    )
}
export default Navbar;