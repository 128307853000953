import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faHandshake } from '@fortawesome/free-solid-svg-icons'
import UpRightArrow from '../Images/UpRightArrowColor.svg'

function Services() {
    return (
        <div class="servicesMainContainer" id="servicesComponentTargetElement">
            <div class="servicesTopContainer">
                <h1 class="servicesSectionHeader"><span class="landingPageMainHeaderSpan">Choose the model that suits you best!</span></h1>
                <h1 class="servicesDescritptionText">
                    Every business has unique needs when it comes to cybersecurity. 
                    To cater to these individual needs, I offer two flexible options for my top-notch vulnerability audit services:
                    Pay-Per-Vulnerability and Fixed-Price Audits.
                </h1>
            </div>

            <div class="servicesRowsContainer">
                <div class="servicesRowsContainerLeftColumn">
                    <FontAwesomeIcon class ="serviceMagnifyingGlass" icon={faMagnifyingGlass} />
                    <h1 class="servicesCardHeader">Pay-Per-Vulnerability</h1>
                    <h1 class="servicesCardDescriptionText">
                        With the Pay-Per-Vulnerability model, you pay exclusively for the vulnerabilities I uncover.
                        As your dedicated security researcher, I will meticulously examine your protocol,
                        identifying and delivering comprehensive reports on each vulnerability found. 
                        This ensures a cost-effective solution, allowing you to directly invest in
                        fortifying your weaknesses, maximizing the value of your security budget.
                    </h1>
                </div>

                <div class="servicesRowsContainerRightColumn">
                    <FontAwesomeIcon class ="serviceHandShake" icon={faHandshake} />
                    <h1 class="servicesCardHeader">Fixed-Price</h1>
                    <h1 class="servicesCardDescriptionText">
                        If you're seeking a budget-friendly and all-encompassing solution, the Fixed-Price Audit service provides a thorough security assessment at a predetermined cost.
                        There are no surprises or hidden fees – just a clear, agreed-upon price for a meticulous examination of your protocol.
                        This choice is ideal for businesses aiming for a straightforward and predictable investment in their security.
                    </h1>
                </div>
            </div>

            <div class="servicesButtonContainer">
                <a href="https://t.me/dimulski" target="_blank" rel="dimulski telegram" class="servicesButtonLinkStyles">
                    <button class="servicesBookAnAuditButton">
                        <div class="landingPagePortfolioTextContainer">
                            <h1 class="servicesBookAnAuditButtonText">Book an audit</h1>
                        </div>
                        <div class="landingPageArrowContainer">
                            <img class="ladningPageArrow" src={UpRightArrow} />
                        </div>
                    </button>
                </a>
            </div>
        </div>
    )
}
export default Services;