import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret, faHandHoldingDollar, faStar } from '@fortawesome/free-solid-svg-icons'


function WhyMe() {
    return (
        <div class="whyMeMainContainer" id ="whyMeComponentTargetElement">
            <div class="whyMeTopContainer">
                <h1 class="whyMeSectionHeader"><span class="landingPageMainHeaderSpan">Why choose me?</span></h1>
            </div>

            <div class="whyMeRowMainCointainer">
                <div class="whyMeLeftColumn">
                    <h1 class="whyMeCardMainHeader">Audit Contest</h1>
                    <FontAwesomeIcon class ="whyMeDetectiveIcon" icon={faUserSecret} />
                    <h1 class="whyMeCardDescText">Presence of top auditors is uncertain.</h1>
                    <div class="whyMeMultipleStarsContainer">
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                    </div>
                    <h1 class="whyMeCardDescText">Auditors may focus on simpler issues. No consequences for oversights.</h1>
                    <FontAwesomeIcon class ="whyMePiggyBankIcon" icon={faHandHoldingDollar} />
                    <h1 class="whyMeCardDescText">Incurs repetitive costs for identifying duplicated bugs.</h1>
                </div>

                <div class="whyMeMiddleColumn">
                    <h1 class="whyMeCardMainHeader">Me</h1>
                    <FontAwesomeIcon class ="whyMeDetectiveIcon" icon={faUserSecret} />
                    <h1 class="whyMeCardDescText">Individual with a publicly verifiable and distinguished track record.</h1>
                    <div class="whyMeMultipleStarsContainer">
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                    </div>
                    <h1 class="whyMeCardDescText">I take 100% responsibility and stake my reputation.</h1>
                    <FontAwesomeIcon class ="whyMePiggyBankIcon" icon={faHandHoldingDollar} />
                    <h1 class="whyMeCardDescText">Zero additional fees. Free marketing utilizing my brand.</h1>
                </div>

                <div class="whyMeRightColumn">
                    <h1 class="whyMeCardMainHeader">Traditional Firm</h1>
                    <FontAwesomeIcon class ="whyMeDetectiveIcon" icon={faUserSecret} />
                    <h1 class="whyMeCardDescText">A team of hired auditors with varying skill levels.</h1>
                    <div class="whyMeMultipleStarsContainer">
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                        <FontAwesomeIcon class ="whyMeSingStarIcon" icon={faStar} />
                    </div>
                    <h1 class="whyMeCardDescText">Auditors receive a fixed salary, regardless of outcomes.</h1>
                    <FontAwesomeIcon class ="whyMePiggyBankIcon" icon={faHandHoldingDollar} />
                    <h1 class="whyMeCardDescText">Includes marketing and administrative overhead.</h1>
                </div>
            </div>
        </div>
    )
}
export default WhyMe;