import { Slide,} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

function Work() {
    return (
        <div class="workContainer" id="workComponentTargetElement">
            <div class="workContainerDescriptionContainer">
                <div class="workContainerDescriptionContainerLeftColum">
                    <h1 class="workSectionHeader"><span class="landingPageMainHeaderSpan">Previous work</span></h1>
                </div>

                <div class="workContainerDescriptionContainerRightColum">
                    <h1 class="workSectionDescription">
                        Audits are not silver bullets, yet a meticulously conducted, 
                        high-quality audit significantly mitigates the risk of security breaches.
                    </h1>
                </div>
            </div>

            <div class="workContainerStatsMainContainer">
                <div class="workContainerStatsMainContainerLeftColumn">
                    <div class="workContainerTopRow">
                        <div class="workContainerTopRowHeader">
                            <h1 class="workContainerCardsHeaders">Trusted by the biggest protocols</h1>
                            <div class="workSliderMainContainer">
                                <Slide 
                                    slidesToShow={3} 
                                    indicators={false} 
                                    arrows={false}  
                                    duration={0}
                                    transitionDuration={5000} 
                                    pauseOnHover={true} 
                                    autoplay={true} 
                                    infinite={true}
                                >
                                    <div class = "workSlideContainer">Coming Soon</div>
                                    <div class = "workSlideContainer">Coming Soon</div>
                                    <div class = "workSlideContainer">Coming Soon</div>
                                    <div class = "workSlideContainer">Coming Soon</div>
                                    <div class = "workSlideContainer">Coming Soon</div>
                                </Slide>
                            </div>
                        </div> 
                    </div>

                    <div class="workContainerBottomRow">
                        <div class="workContainerBottomRowLeftColumn">
                            <h1 class="workContainerCardsHeadersBottomColumns"> >50</h1>
                            <h1 class="workSectioBottomBoxesDesc">
                                Critical, High & Medium vulnerabilities discovered.
                            </h1>
                        </div>

                        <div class="workContainerBottomRowRightColumn">
                            <h1 class="workContainerCardsHeadersBottomColumns"> >$0M (USD)</h1>
                            <h1 class="workSectioBottomBoxesDesc">
                            in TVL held by audited contracts
                            </h1>
                        </div>
                    </div>                    
                </div>

                <div class="workContainerStatsMainContainerRightColumn">
                    <h1 class="workContainerAuditStatsMainHeader">Audit stats</h1>
                    <h1 class="workContainerAuditStatsHeader">March 2024</h1>
                    <div class="workContainerAuditStatsInnerMainContainer">
                        <div class="workContainerAuditStatsInnerMainContainerFirstRow">
                            <div class="workContainerAuditStatsSmallContainer">
                                <div class="workSectionSpanOneContainer">
                                    <div class="workSectionSpanOne"></div>
                                </div>
                                <div class="workSectionHeaderContainer">
                                    <h1 class="workContainerAuditStatsSmallContainerHeader">
                                        0 Audits
                                    </h1>
                                </div>
                            </div>

                            <div class="workContainerAuditStatsSmallContainer">
                                <div class="workSectionSpanOneContainer">
                                    <div class="workSectionSpanTwo"></div>
                                </div>
                                <div class="workSectionHeaderContainer">
                                    <h1 class="workContainerAuditStatsSmallContainerHeader">
                                        0 nSLOC
                                    </h1>
                                </div>
                            </div>
                        </div>

                        <div class="workContainerAuditStatsInnerMainContainerFirstRow">
                            <div class="workContainerAuditStatsSmallContainer">
                                <div class="workSectionSpanOneContainer">
                                    <div class="workSectionSpanThree"></div>
                                </div>
                                <div class="workSectionHeaderContainer">
                                    <h1 class="workContainerAuditStatsSmallContainerHeader">
                                        0 Critical
                                    </h1>
                                </div>
                            </div>

                            <div class="workContainerAuditStatsSmallContainer">
                                <div class="workSectionSpanOneContainer">
                                    <div class="workSectionSpanFour"></div>
                                </div>
                                <div class="workSectionHeaderContainer">
                                    <h1 class="workContainerAuditStatsSmallContainerHeader">
                                        0 High
                                    </h1>
                                </div>
                            </div>
                        </div>

                        <div class="workContainerAuditStatsInnerMainContainerFirstRow">
                            <div class="workContainerAuditStatsSmallContainer">
                                <div class="workSectionSpanOneContainer">
                                    <div class="workSectionSpanFive"></div>
                                </div>
                                <div class="workSectionHeaderContainer">
                                    <h1 class="workContainerAuditStatsSmallContainerHeader">
                                        0 Medium
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Work;