import Navbar from './Components/Navbar'
import LandingPage from './Components/LandingPage'
import Services from './Components/Services'
import Work from './Components/Work'
import WhyMe from './Components/WhyMe'
import Reviews from './Components/Reviews'
import Footer from './Components/Footer'
import './App.css';

function App() {
  return (
    <div className="background">
      <Navbar></Navbar>
      <LandingPage></LandingPage>
      <Services></Services>
      <Work></Work>
      <WhyMe></WhyMe>
      {/* <h1 calss="headerStuff"><span class="text-gradient">text gradient effect</span></h1> */}
      <Reviews></Reviews>
      <Footer></Footer>
    </div>
  );
}

export default App;
